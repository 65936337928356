// import { gsap } from "gsap";

const htmlElement = document.documentElement;

/* gsap.to(".image", {
   y: 500   
}); */

// Toggle Menu
function toggleMenu() {
  //console.log("Hello world!");
  document.getElementById("menu").classList.toggle("hidden");

  var contents = document.getElementsByClassName("content");
  for (i = 0; i < contents.length; i++) { 
    contents[i].classList.toggle("nodisplay");
  }
  document.getElementsByClassName("main-footer")[0].classList.toggle("nodisplay");
  //document.getElementsByClassName("content")[0].classList.toggle("nodisplay");
  //document.getElementsByClassName("content")[1].classList.toggle("nodisplay");
  
}  

document.getElementsByClassName("menuicon")[0].addEventListener("click", function() { 
  toggleMenu();
})

document.getElementsByClassName("menuicon")[1].addEventListener("click", function() { 
  toggleMenu();
})



// Services Accordion + Kreis Animation

var acc = document.getElementsByClassName("service-name");

var i;
var state;

for (i = 0; i < acc.length; i++) {

  acc[i].addEventListener("click", function() {

 
   
    /* Toggle between adding and removing the "active" class,
    to highlight the button that controls the panel */
    
    if(this.classList.contains("active")){
      //console.log("Already Active");
      this.classList.remove("active");
      this.nextElementSibling.classList.add("hide");
    } else {
      let active = document.querySelectorAll(".active");
      for(let j = 0; j < active.length; j++){
      active[j].nextElementSibling.classList.add("hide");
      active[j].classList.remove("active");
      
    }
    this.classList.toggle("active");
    this.nextElementSibling.classList.toggle("hide");
    }

   

    // LASS DIE KREISE TANZEN
    // console.log(this.id);
    state = this.id;
    /* Toggle between hiding and showing the active panel */
    
    document.getElementsByClassName("circle")[0].classList.remove("state0");
    document.getElementsByClassName("circle")[0].classList.remove("state1");
    document.getElementsByClassName("circle")[0].classList.remove("state2");
    document.getElementsByClassName("circle")[0].classList.remove("state3");
    document.getElementsByClassName("circle")[0].classList.remove("state4");
    document.getElementsByClassName("circle")[0].classList.remove("state5");
    
    document.getElementsByClassName("circle")[0].classList.add("state"+state);
   

   
    
    /* ANIMATION DER ILLUSTRATION */
    /*
    document.getElementsByClassName("circle")[0].classList.toggle("move2");
    document.getElementsByClassName("circle")[1].classList.toggle("move2");
    document.getElementsByClassName("circle")[0].classList.toggle("move");
    document.getElementsByClassName("circle")[1].classList.toggle("move");
    */

    // panel.scrollIntoView();
   
    /* if (panel.style.display === "block") {
      panel.style.display = "none";
    } else {
      panel.style.display = "block";
    }*/
  });
};


// Type Effect Homepage Headline

let consoleElement = document.getElementById('dropzone');
if (consoleElement) {
consoleText(['UI/UX', 'Designsysteme', 'Identitäten', 'Konzepte', 'Interfaces', 'Websites', 'Apps'],'dropzone');
}

function consoleText(words, id) {
  
  var visible = true;
  var con = document.getElementById('console');
  var letterCount = 1;
  var x = 1;
  var waiting = false;
  var target = document.getElementById(id)
  //target.setAttribute('style', 'color:' + colors[0])
  window.setInterval(function() {

    if (letterCount === 0 && waiting === false) {
      waiting = true;
      target.innerHTML = words[0].substring(0, letterCount)
      window.setTimeout(function() {
       
        var usedWord = words.shift();
        words.push(usedWord);
        x = 1;
        
        letterCount += x;
        waiting = false;
      }, 1000)
    } else if (letterCount === words[0].length + 1 && waiting === false) {
      waiting = true;
      window.setTimeout(function() {
        x = -1;
        letterCount += x;
        waiting = false;
      }, 1000)
    } else if (waiting === false) {
      target.innerHTML = words[0].substring(0, letterCount)
      letterCount += x;
    }
  }, 120)
  window.setInterval(function() {
    if (visible === true) {
      con.className = 'console-underscore hidden'
      visible = false;

    } else {
      con.className = 'console-underscore'

      visible = true;
    }
  }, 400)
}

// FAQ SEITE ACCORDION

var faqQuestionContainers = document.querySelectorAll('.faq-question-container');

faqQuestionContainers.forEach(function(container) {
    container.addEventListener('click', function() {
        var answerBlock = this.querySelector('.faq-answer-block');
        var chevron = this.querySelector('.faq-chevron');

        // Schließe alle anderen Antworten und drehe ihre Chevrons zurück
        document.querySelectorAll('.faq-answer-block').forEach(function(el) {
            if (el !== answerBlock) {
                el.classList.remove('show');
                var otherChevron = el.closest('.faq-question-container').querySelector('.faq-chevron');
                if (otherChevron) {
                    otherChevron.classList.remove('rotate');
                }
            }
        });

        // Toggle aktuelle Antwort und Chevron-Drehung
        answerBlock.classList.toggle('show');
        if (answerBlock.classList.contains('show')) {
            chevron.classList.add('rotate');
        } else {
            chevron.classList.remove('rotate');
        }
    });
});